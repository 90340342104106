import { local } from 'd3';
import { format, isValid, subDays } from 'date-fns';
import { stringToDate } from '../Helper/commons';

import {
  SET_SELECTED_PERSONNEL,
  SET_PARAM,
  SET_MODAL,
  SELECTED_STORE_CHANGE,
  SELECTED_AREA_CHANGE,
  SELECTED_RECT_CHANGE,
  SELECTED_COMPARISON_STORE_CHANGE,
  SET_START_DATE,
  SET_END_DATE,
  SET_START_TIME,
  SET_START_TIME_VALUE,
  SET_END_TIME,
  SET_START_HEATMAP_DATE,
  SET_END_HEATMAP_DATE,
  SET_START_HEATMAP_TIME,
  SET_END_HEATMAP_TIME,
  SET_PARAMS,
  REMOVE_PARAMS,
  FUNNEL_THRESHOLDS_ADD,
  FUNNEL_THRESHOLDS_REMOVE,
  SET_INITIAL,
  SET_SYNC_BOUNDARIES,
  SET_FILTER_TYPE,
  SET_STATISTIC,
  SET_DATE_PARAMS,
  SET_COMPARISON_PARAMS,
  SET_HOMEBRAND_PARAMS,
  SET_PERFORMANCE_TABLE_PARAMS,
  SET_PREVSTART_DATE,
  SET_PREVEND_DATE,
  SET_PREVSTART_TIME,
  SET_PREVEND_TIME,
  SET_BACKUPDATES,
  INITIALIZE_STATE,
  RESET_STATE,
  SELECTED_DEPARTMENT_CHANGE,
  SET_SUGGESTION_DATES,
  SELECT_PERSONNELID_CHANGE,
  SET_COMPARISON_LAYES,
  SET_TRENDS_FILTER_TYPE,
  CLEAR_SELECTED_STORE,
  SELECTED_CHECKOUT_COMPARISON_STORE_CHANGE,
  SELECTED_AGEGENDER_COMPARISON_STORE_CHANGE,
  CONVERSION_RATE_THRESOLDS,
  CONVERSION_RATE_THRESOLDS_REMOVE,
  SET_HOMEAGEBRAND_PARAMS,
  SET_CATEGORY_FILTER_TYPE,
  SET_SELECTED_DEPARTMENTID,
  TOGGLE_ALL_BRAND,
  SET_IS_PERSONNEL_SELECTED,
  WAITING_TIMES_THERESOLDS,
  WAITING_TIMES_THERESOLDS_REMOVE,
  SET_END_TIME_VALUE,
  SET_METRIC_TYPE,
  SET_SELECTED_STORE_IDS,
  SET_SHOW_TOTAL,
  SET_ALL_STORE_IDS,
  SET_CORRELATION_THRESHOLD,
  TOGGLE_SHOW_NAVBAR_ON_SMALL_SCREEN,
  SET_SKETCH_ID,
  DWELL_THRESHOLDS_ADD,
  DWELL_THRESHOLDS_REMOVE
} from '../ReduxActions/types';
var initialState = {
  PerformanceTableChangedDate: null,
  modal: false,
  pageHeader: {},
  SelectedCameraFilter: {},
  syncKeys: {},
  statistic: {
    weatherAndTraffic: false,
    trafficAndSales: false,
    conversionRateMarket: false,
    conversionRateVehicle: false,
    vehicleNumberAndMarket: false
  }
};

export function formatDateDisplay(date, dateDefaultText = '') {
  if (!date) return dateDefaultText;
  // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));
  // if (isSafari) {
  //     return format(new Date(date.toISOString().replace('Z', '')), 'dd/MM/yyyy');
  // } else
  //     return format(date, 'dd/MM/yyyy');
  return format(date, 'dd/MM/yyyy');
}
function getComparisonDates(paramPrefix, localStorageTag) {
  let dates = localStorage[localStorageTag]
    ? JSON.parse(localStorage[localStorageTag])
    : {};

  const Sdate =
    dates.startDate && isValid(new Date(dates.startDate))
      ? formatDateDisplay(new Date(dates.startDate))
      : formatDateDisplay(subDays(new Date(), 14));

  const Edate =
    dates.endDate && isValid(new Date(dates.endDate))
      ? formatDateDisplay(new Date(dates.endDate))
      : formatDateDisplay(new Date());

  const Stime = dates.stime || '10:00';
  const Etime = dates.etime || '22:00';

  return {
    [paramPrefix + 'Sdate']: Sdate,
    [paramPrefix + 'Edate']: Edate,
    [paramPrefix + 'Stime']: Stime,
    [paramPrefix + 'Etime']: Etime
  };
}
export default function (state = null, action) {
  let nextState = { ...state };
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState,
        allStoreIds: [],
        showTotal: false,
        metricType: 6,
        selectedStoreIds: [],
        sdate: localStorage.sdate
          ? localStorage.sdate
          : formatDateDisplay(subDays(new Date(), 14)),
        edate: localStorage.edate
          ? localStorage.edate
          : formatDateDisplay(new Date()),
        stime: localStorage.stime ? localStorage.stime : '10:00',
        etime: localStorage.etime ? localStorage.etime : '22:00',
        startTime:
          localStorage.startTime !== undefined ? localStorage.startTime : 600,
        endTime:
          localStorage.endTime !== undefined ? localStorage.endTime : 1320,
        Heatmapsdate: localStorage.Heatmapsdate
          ? localStorage.Heatmapsdate
          : formatDateDisplay(subDays(new Date(), 1)),
        Heatmapedate: localStorage.Heatmapedate
          ? localStorage.Heatmapedate
          : formatDateDisplay(new Date()),
        Heatmapstime: localStorage.Heatmapstime
          ? localStorage.Heatmapstime
          : '10:00',
        Heatmapetime: localStorage.Heatmapetime
          ? localStorage.Heatmapetime
          : '22:00',
        storeID:
          localStorage.SelectedStores &&
          JSON.parse(localStorage.getItem('SelectedStores')).globalStoreParam
            ? JSON.parse(localStorage.SelectedStores).globalStoreParam.Id
            : null,
        brandID: localStorage.user
          ? JSON.parse(localStorage.user).details.BrandId
          : null,
        globalBrandID: localStorage.SelectedBrand
          ? JSON.parse(localStorage.SelectedBrand).globalBrandParam.Id
          : localStorage.user
          ? JSON.parse(localStorage.user).details.BrandId
          : null,
        heatmapCameraID:
          localStorage.SelectedCameras &&
          JSON.parse(localStorage.getItem('SelectedCameras')).heatmapPageCamera
            ? JSON.parse(localStorage.SelectedCameras).heatmapPageCamera.Id
            : null,
        settingsCameraID:
          localStorage.SelectedCameras &&
          JSON.parse(localStorage.getItem('SelectedCameras')).settingsPageCamera
            ? JSON.parse(localStorage.SelectedCameras).settingsPageCamera.Id
            : null,
        areaPageSketchID:
          localStorage.SelectedSketchs &&
          JSON.parse(localStorage.getItem('SelectedSketchs')).areaPageSketch
            ? JSON.parse(localStorage.SelectedSketchs).areaPageSketch.Id
            : null,
        settingsSketchID:
          localStorage.SelectedSketchs &&
          JSON.parse(localStorage.getItem('SelectedSketchs')).settingsPageSketch
            ? JSON.parse(localStorage.SelectedSketchs).settingsPageSketch.Id
            : null,
        trendsSketch: localStorage.trendsSketch
          ? localStorage.trendsSketch
          : undefined,
        trendsFilterType: localStorage.trendsFilterType
          ? JSON.parse(localStorage.trendsFilterType)
          : null,
        rectID: localStorage.SelectedRect
          ? JSON.parse(localStorage.SelectedRect).Id
          : null,
        DepartmentID:
          localStorage.selectedDepartment &&
          JSON.parse(localStorage.selectedDepartment)
            ? JSON.parse(localStorage.selectedDepartment).Id
            : null,
        comparisonStore: localStorage.comparisonStore
          ? JSON.parse(localStorage.comparisonStore)
          : undefined,
        comparisonStoreId: localStorage.comparisonStore
          ? JSON.parse(localStorage.comparisonStore).Id
          : undefined,
        comparisonStore2: localStorage.comparisonStore2
          ? JSON.parse(localStorage.comparisonStore2)
          : undefined,
        comparisonStore2Id: localStorage.comparisonStore2
          ? JSON.parse(localStorage.comparisonStore2).Id
          : undefined,
        ...getComparisonDates('comparisonOne', 'ComparisonWithDate_1'),
        ...getComparisonDates('comparisonTwo', 'ComparisonWithDate_2'),
        comparisonSketchLayers: localStorage.comparisonSketchLayers
          ? JSON.parse(localStorage.comparisonSketchLayers)
          : {},
        funnelthresholds: localStorage.funnelthresholds
          ? JSON.parse(localStorage.funnelthresholds)
          : [3, 10, 15],
        conversionRateThresholds: localStorage.conversionRateThresholds
          ? JSON.parse(localStorage.conversionRateThresholds)
          : [3, 10, 15],
        filterType: localStorage.filterType ? localStorage.filterType : '1',
        HomeBrandStoreID: localStorage.HomeBrand
          ? JSON.parse(localStorage.HomeBrand).HomeBrandStoreID
          : null,
        HomeBrandfilterType: localStorage.HomeBrand
          ? JSON.parse(localStorage.HomeBrand).HomeBrandfilterType
          : null,
        HomeBrandDepartmentID: localStorage.HomeBrand
          ? JSON.parse(localStorage.HomeBrand).HomeBrandDepartmentID
          : null,
        HomeBrandSubDepartmentID: localStorage.HomeBrand
          ? JSON.parse(localStorage.HomeBrand).HomeBrandSubDepartmentID
          : null,
        ComparisonV2: localStorage.ComparisonV2
          ? JSON.parse(localStorage.ComparisonV2)
          : {},
        prevsdate: localStorage.Prevsdate
          ? localStorage.Prevsdate
          : formatDateDisplay(subDays(new Date(), 29)),
        prevedate: localStorage.Prevedate
          ? localStorage.Prevedate
          : formatDateDisplay(subDays(new Date(), 15)),
        prevstime: localStorage.Prevstime ? localStorage.Prevstime : '10:00',
        prevetime: localStorage.Prevetime ? localStorage.Prevetime : '22:00',
        Suggestionsdate: localStorage.Suggestionsdate
          ? localStorage.Suggestionsdate
          : formatDateDisplay(subDays(new Date(), 14)),
        Suggestionedate: localStorage.Suggestionedate
          ? localStorage.Suggestionedate
          : formatDateDisplay(new Date()),
        Suggestiononesdate: localStorage.SuggestionRequest1
          ? formatDateDisplay(
              JSON.parse(localStorage.SuggestionRequest1).startDate
            )
          : formatDateDisplay(subDays(new Date(), 14)),
        Suggestiononeedate: localStorage.SuggestionRequest1
          ? formatDateDisplay(
              JSON.parse(localStorage.SuggestionRequest1).endDate
            )
          : formatDateDisplay(new Date()),
        Suggestiontwosdate: localStorage.SuggestionRequest2
          ? formatDateDisplay(
              JSON.parse(localStorage.SuggestionRequest2).startDate
            )
          : formatDateDisplay(subDays(new Date(), 14)),
        Suggestiontwoedate: localStorage.SuggestionRequest2
          ? formatDateDisplay(
              JSON.parse(localStorage.SuggestionRequest2).endDate
            )
          : formatDateDisplay(new Date()),
        SelectedSketchLayer: localStorage.SelectedSketchLayer
          ? parseInt(localStorage.SelectedSketchLayer)
          : 1,
        // PerformanceTableDateOne: localStorage.PerformanceTableDateOne ? {
        //     ...JSON.parse(localStorage.PerformanceTableDateOne),
        //     startDate: formatDateDisplay(JSON.parse(localStorage.PerformanceTableDateOne).startDate),
        //     endDate: formatDateDisplay(JSON.parse(localStorage.PerformanceTableDateOne).endDate)} :
        //     {
        //         startDate: formatDateDisplay(subDays(new Date(), 29)),
        //         endDate: formatDateDisplay(subDays(new Date(), 15)),  startTime: 600, endTime: 1320,stime:(localStorage.stime ? localStorage.stime : '10:00'), etime: (localStorage.etime ? localStorage.etime : '22:00')
        //     },
        personnelID: localStorage.personnelID
          ? JSON.parse(localStorage.personnelID)
          : null,
        backupDates: [
          localStorage.sdate
            ? stringToDate(localStorage.sdate)
            : subDays(new Date(), 14),
          localStorage.edate ? stringToDate(localStorage.edate) : new Date()
        ],
        trendMetric: localStorage.trendMetric ? localStorage.trendMetric : 0,
        SuggestionsPeriod: localStorage.SuggestionsPeriod
          ? parseInt(localStorage.SuggestionsPeriod)
          : 0,
        SuggestionSelectDepId: localStorage.SuggestionSelectDepId
          ? parseInt(localStorage.SuggestionSelectDepId)
          : null,
        SuggestionSelectRectId: localStorage.SuggestionSelectRectId
          ? parseInt(localStorage.SuggestionSelectRectId)
          : null,
        TimeZoneOffset: localStorage.TimeZoneOffset
          ? parseInt(localStorage.TimeZoneOffset)
          : null,
        checkOutStore: localStorage.checkOutStore
          ? JSON.parse(localStorage.checkOutStore)
          : undefined,
        checkOutStoreId: localStorage.checkOutStore
          ? JSON.parse(localStorage.checkOutStore).Id
          : undefined,
        checkOutStore2: localStorage.checkOutStore2
          ? JSON.parse(localStorage.checkOutStore2)
          : undefined,
        checkOutStore2: localStorage.checkOutStore2
          ? JSON.parse(localStorage.checkOutStore2).Id
          : undefined,
        ageGenderStore: localStorage.ageGenderStore
          ? JSON.parse(localStorage.ageGenderStore)
          : undefined,
        ageGenderStore2: localStorage.ageGenderStore2
          ? JSON.parse(localStorage.ageGenderStore2)
          : undefined,
        ageGenderStoreId: localStorage.ageGenderStore
          ? JSON.parse(localStorage.ageGenderStore).Id
          : undefined,
        ageGenderStore2Id: localStorage.ageGenderStore2
          ? JSON.parse(localStorage.ageGenderStore2).Id
          : undefined,
        HomeAgeStoreID: localStorage.HomeAgeBrand
          ? JSON.parse(localStorage.HomeAgeBrand).HomeAgeStoreID
          : null,
        HomeCategoryDepartmentID: localStorage.HomeCategoryDepartmentID
          ? JSON.parse(localStorage.HomeCategoryDepartmentID)
          : null,
        DepartmentFilterType: localStorage.DepartmentFilterType
          ? JSON.parse(localStorage.DepartmentFilterType)
          : null,
        showAllBrand: false,
        selectedPersonnel: {},
        isPersonnelSelected: false,
        waitingTimesThresholds:
          localStorage.user &&
          JSON.parse(localStorage.user).details?.PanelConfig &&
          JSON.parse(JSON.parse(localStorage.user).details.PanelConfig)
            ?.waitingTimesThreshold
            ? JSON.parse(JSON.parse(localStorage.user).details.PanelConfig)
                ?.waitingTimesThreshold
            : [],
        funneltdwells:
          localStorage.user &&
          JSON.parse(localStorage.user).details?.PanelConfig &&
          JSON.parse(JSON.parse(localStorage.user).details.PanelConfig)
            ?.funneltdwells
            ? JSON.parse(JSON.parse(localStorage.user).details.PanelConfig)
                ?.funneltdwells
            : [],
        CustomTimeZoneOffset: localStorage.CustomTimeZoneOffset
          ? parseInt(localStorage.CustomTimeZoneOffset)
          : null,
        CorrelationThreshold: 0,
        showNavbarOnSmallScreen: false, // hide navbar on small screens initially,
        sketchId: null,
        ...getComparisonDates('CampaingnStart', 'CampaingnStart'),
        ...getComparisonDates('CampaingnFinish', 'CampaingnFinish'),
        ...getComparisonDates('ageGenderComparisonOne', 'ageGenderDateOne'),
        ...getComparisonDates('ageGenderComparisonTwo', 'ageGenderDateTwo'),
        ...getComparisonDates('checkOutComparisonOne', 'checkoutDateOne'),
        ...getComparisonDates('checkOutComparisonTwo', 'checkoutDateTwo')
      };

      break;
    case RESET_STATE:
      nextState = null;
      break;
    case SET_PARAM:
      nextState[action.paramName] = action.value;
      localStorage.setItem(action.paramName, action.value);
      break;
    case SET_PARAMS:
      action.paramsArray.forEach(element => {
        nextState[element.name] = element.value;
      });
      break;
    case SET_MODAL:
      nextState[action.paramName] = action.value;
      break;
    case REMOVE_PARAMS:
      action.paramsArray.forEach(element => {
        delete nextState[element.name];
        localStorage.removeItem(element.name);
      });
      break;
    case SET_INITIAL:
      nextState = {
        ...state,
        ...action.payload
      };
      break;
    case SELECTED_STORE_CHANGE:
      nextState = {
        ...state,
        storeID: action.payload.Id,
        cameraID: null,
        sketchID: null,
        rectID: null,
        DepartmentID: null,
        personnelID: null
        // SelectedSketchLayer: 1
      };
      break;
    case CLEAR_SELECTED_STORE:
      nextState = {
        ...state,
        storeID: null,
        cameraID: null,
        sketchID: null,
        rectID: null,
        DepartmentID: null,
        personnelID: null
        // SelectedSketchLayer: 1
      };
      localStorage.removeItem('SelectedStores');
      break;
    case SELECTED_COMPARISON_STORE_CHANGE:
      nextState[action.uniqueName] = action.payload;
      nextState[action.uniqueName + 'Id'] = action.payload.Id;
      localStorage.setItem(action.uniqueName, JSON.stringify(action.payload));
      break;
    case SET_START_DATE:
      const sdate = formatDateDisplay(action.payload);
      if (sdate !== state.sdate) {
        nextState['sdate'] = sdate;
        localStorage.setItem('sdate', sdate);
      }
      break;
    case SET_END_DATE:
      const edate = formatDateDisplay(action.payload);
      if (edate !== state.edate) {
        nextState['edate'] = edate;
        localStorage.setItem('edate', edate);
      }
      break;
    case SET_END_TIME:
      const etime = action.payload;
      if (etime && etime !== state.etime) {
        nextState['etime'] = etime;
        localStorage.setItem('etime', etime);
      }
      break;
    case SET_START_TIME:
      const stime = action.payload;
      if (stime && stime !== state.stime) {
        nextState['stime'] = stime;
        localStorage.setItem('stime', stime);
      }
      break;
    case SET_START_TIME_VALUE:
      const startTime = action.payload;
      if (startTime !== undefined && startTime !== state.startTime) {
        nextState['startTime'] = startTime;
        localStorage.setItem('startTime', startTime);
      }
      break;
    case SET_END_TIME_VALUE:
      const endTime = action.payload;
      if (endTime !== undefined && endTime !== state.endTime) {
        nextState['endTime'] = endTime;
        localStorage.setItem('endTime', endTime);
      }
      break;
    case SET_START_HEATMAP_DATE:
      const Heatmapsdate = formatDateDisplay(action.payload);
      if (Heatmapsdate !== state.Heatmapsdate) {
        nextState['Heatmapsdate'] = Heatmapsdate;
        localStorage.setItem('Heatmapsdate', Heatmapsdate);
      }
      break;
    case SET_END_HEATMAP_DATE:
      const Heatmapedate = formatDateDisplay(action.payload);
      if (Heatmapedate !== state.Heatmapedate) {
        nextState['Heatmapedate'] = Heatmapedate;
        localStorage.setItem('Heatmapedate', Heatmapedate);
      }
      break;
    case SET_END_HEATMAP_TIME:
      const Heatmapetime = action.payload;
      if (Heatmapetime && Heatmapetime !== state.Heatmapetime) {
        nextState['Heatmapetime'] = Heatmapetime;
        localStorage.setItem('Heatmapetime', Heatmapetime);
      }
      break;
    case SET_START_HEATMAP_TIME:
      const Heatmapstime = action.payload;
      if (Heatmapstime && Heatmapstime !== state.Heatmapstime) {
        nextState['Heatmapstime'] = Heatmapstime;
        localStorage.setItem('Heatmapstime', Heatmapstime);
      }
      break;
    case SELECTED_AREA_CHANGE:
      nextState = {
        ...state,
        areaID: action.payload.Id,
        sketchID: action.payload.Id
      };
      break;
    case SELECTED_RECT_CHANGE:
      nextState['rectID'] = action.payload.Id;
      nextState['DepartmentID'] = null;
      break;
    case SELECT_PERSONNELID_CHANGE:
      nextState['personnelID'] = action.payload;
      break;
    case SELECTED_DEPARTMENT_CHANGE:
      nextState['DepartmentID'] = action.payload.Id;
      break;
    case FUNNEL_THRESHOLDS_ADD:
      let ft = state.funnelthresholds;
      if (ft.indexOf(action.payload) === -1) {
        ft.push(action.payload);
        nextState['funnelthresholds'] = [...ft];
        localStorage.setItem(
          'funnelthresholds',
          JSON.stringify(nextState.funnelthresholds)
        );
      }
      break;
    case FUNNEL_THRESHOLDS_REMOVE:
      let fnt = state.funnelthresholds;
      if (fnt.indexOf(action.payload) !== -1) {
        const index = fnt.findIndex(val => val === action.payload);
        fnt.splice(index, 1);
        nextState['funnelthresholds'] = [...fnt];
        localStorage.setItem(
          'funnelthresholds',
          JSON.stringify(nextState.funnelthresholds)
        );
      }
      break;
    case SET_SYNC_BOUNDARIES:
      nextState.syncKeys[action.payload.syncKey] = {
        ...nextState.syncKeys[action.payload.syncKey],
        [action.payload.name]: action.payload.value
      };
      break;
    case SET_FILTER_TYPE:
      const ftype = action.payload;
      if (ftype && ftype !== state.filterType) {
        nextState['filterType'] = ftype;
        localStorage.setItem('filterType', ftype);
      }
      break;
    case SET_TRENDS_FILTER_TYPE:
      const trendsFtype = action.payload;
      nextState['trendsFilterType'] = trendsFtype;
      localStorage.setItem('trendsFilterType', trendsFtype);
      break;
    case SET_STATISTIC:
      nextState['statistic'][action.payload.id] = action.payload.show;
      break;
    case SET_DATE_PARAMS:
      nextState[action.payload.id] = {
        ...action.payload.date
      };
      break;
    case SET_COMPARISON_PARAMS:
      nextState['ComparisonV2'][action.payload.key] = action.payload.data;
      localStorage.setItem(
        'ComparisonV2',
        JSON.stringify(nextState['ComparisonV2'])
      );
      break;
    case SET_COMPARISON_LAYES:
      nextState.comparisonSketchLayers = {
        ...state.comparisonSketchLayers,
        ...action.payload
      };
      localStorage.setItem(
        'comparisonSketchLayers',
        JSON.stringify(nextState['comparisonSketchLayers'])
      );
      break;
    case SET_HOMEBRAND_PARAMS:
      nextState[action.payload.key] = action.payload.data;
      break;
    case SET_HOMEAGEBRAND_PARAMS:
      nextState[action.payload.key] = action.payload.data;
      break;
    case SET_PREVSTART_DATE:
      const prevsdate = formatDateDisplay(action.payload);
      if (prevsdate !== state.prevsdate) {
        nextState['prevsdate'] = prevsdate;
        localStorage.setItem('prevsdate', prevsdate);
      }
      break;
    case SET_PREVEND_DATE:
      const prevedate = formatDateDisplay(action.payload);
      if (prevedate !== state.prevedate) {
        nextState['prevedate'] = prevedate;
        localStorage.setItem('prevedate', prevedate);
      }
      break;
    case SET_PREVEND_TIME:
      const prevetime = action.payload;
      if (prevetime && prevetime !== state.prevetime) {
        nextState['prevetime'] = prevetime;
        localStorage.setItem('prevetime', prevetime);
      }
      break;
    case SET_PREVSTART_TIME:
      const prevstime = action.payload;
      if (prevstime && prevstime !== state.prevstime) {
        nextState['prevstime'] = prevstime;
        localStorage.setItem('prevstime', prevstime);
      }
      break;
    case SET_SUGGESTION_DATES:
      const Suggestionsdate = action.payload[0];
      if (Suggestionsdate !== state.Suggestionsdate) {
        nextState['Suggestionsdate'] = Suggestionsdate;
        localStorage.setItem('Suggestionsdate', Suggestionsdate);
      }
      const Suggestionedate = action.payload[1];
      if (Suggestionedate !== state.Suggestionedate) {
        nextState['Suggestionedate'] = Suggestionedate;
        localStorage.setItem('Suggestionedate', Suggestionedate);
      }
      break;
    case SET_BACKUPDATES:
      nextState['backupDates'] = action.payload;
      break;
    case SELECTED_CHECKOUT_COMPARISON_STORE_CHANGE:
      nextState[action.uniqueName] = action.payload;
      nextState[action.uniqueName + 'Id'] = action.payload.Id;
      localStorage.setItem(action.uniqueName, JSON.stringify(action.payload));
      break;
    case SELECTED_AGEGENDER_COMPARISON_STORE_CHANGE:
      nextState[action.uniqueName] = action.payload;
      nextState[action.uniqueName + 'Id'] = action.payload.Id;
      localStorage.setItem(action.uniqueName, JSON.stringify(action.payload));
      break;
    case CONVERSION_RATE_THRESOLDS:
      let ct = state.conversionRateThresholds;
      if (ct.indexOf(action.payload) === -1) {
        ct.push(parseInt(action.payload));
        nextState['conversionRateThresholds'] = [...ct];
        localStorage.setItem(
          'conversionRateThresholds',
          JSON.stringify(nextState.conversionRateThresholds)
        );
      }
      break;
    case CONVERSION_RATE_THRESOLDS_REMOVE:
      let crt = state.conversionRateThresholds;
      if (crt.indexOf(action.payload) !== -1) {
        const index = crt.findIndex(val => val === action.payload);
        crt.splice(index, 1);
        nextState['conversionRateThresholds'] = [...crt];
        localStorage.setItem(
          'conversionRateThresholds',
          JSON.stringify(nextState.conversionRateThresholds)
        );
      }
      break;
    case WAITING_TIMES_THERESOLDS:
      nextState['waitingTimesThresholds'] = action.payload;
      break;
    case WAITING_TIMES_THERESOLDS_REMOVE:
      nextState['waitingTimesThresholds'] = action.payload;
      break;
    case DWELL_THRESHOLDS_ADD:
      nextState['funneltdwells'] = action.payload;
      break;
    case DWELL_THRESHOLDS_REMOVE:
      nextState['funneltdwells'] = action.payload;
      break;
    case SET_CATEGORY_FILTER_TYPE:
      const depFilterType = action.payload;
      nextState['DepartmentFilterType'] = depFilterType;
      localStorage.setItem('DepartmentFilterType', depFilterType);
      break;
    case SET_SELECTED_DEPARTMENTID:
      nextState['HomeCategoryDepartmentID'] = action.payload;
      localStorage.setItem('HomeCategoryDepartmentID', action.payload);
      break;
    case TOGGLE_ALL_BRAND:
      nextState['showAllBrand'] = !state.showAllBrand;
      break;
    case SET_SELECTED_PERSONNEL:
      nextState['selectedPersonnel'] = action.payload;
      break;
    case SET_IS_PERSONNEL_SELECTED:
      nextState['isPersonnelSelected'] = action.payload;
      break;
    case SET_METRIC_TYPE:
      nextState['metricType'] = action.payload;
      break;
    case SET_SELECTED_STORE_IDS:
      nextState['selectedStoreIds'] = action.payload;
      break;
    case SET_SHOW_TOTAL:
      nextState['showTotal'] = action.payload;
      break;
    case SET_ALL_STORE_IDS:
      nextState['allStoreIds'] = action.payload;
      break;
    case SET_CORRELATION_THRESHOLD:
      nextState['CorrelationThreshold'] = action.payload;
      break;
    case TOGGLE_SHOW_NAVBAR_ON_SMALL_SCREEN:
      nextState['showNavbarOnSmallScreen'] =
        !nextState['showNavbarOnSmallScreen'];
      break;
    case SET_SKETCH_ID:
      nextState['sketchId'] = action.payload;
      break;
    default:
      break;
  }
  return nextState;
}
